@import './shared';

:root {
  // color tokens
  $accent: #6a3de8;
  $ink: #332244;
  --color-accent: #{$accent};
  --color-accent-tonal: #{rgba($accent, .08)};
  --color-ink: #{$ink};
  --color-ink-2: #6f687a;
  --color-ink-placeholder: #898692;
  --color-ink-disabled: #b4b2b9;
  --color-background: #fff;
  --color-background-hover: #{rgba($ink, .03)};
  --color-background-active: #{rgba($ink, .06)};
  --color-background-2: #f5f4f8;
  --color-background-popup: var(--color-background);
  --color-border: #e0dee5;
  --color-focus-outline: #ffbb29;
}

@mixin dark-theme {
  // color tokens
  $accent: #b79cff;
  --color-accent: #{$accent};
  --color-accent-tonal: #{rgba($accent, .24)};
  --color-ink: #fff;
  --color-ink-2: #e4e0ec;
  --color-ink-placeholder: #b6b0c7;
  --color-ink-disabled: #8e889e;
  --color-background: #28222a;
  --color-background-hover: #{rgba(#fff, .04)};
  --color-background-active: #{rgba(#fff, .08)};
  --color-background-2: #141110;
  --color-background-popup: var(--color-background-2);
  --color-border: #4c4450;
  --color-focus-outline: #ffd382;
}

body.dark-theme {
  @include dark-theme;
  color-scheme: dark;
}

body:not(.is-keyboard-mode) {
  --color-focus-outline: transparent !important;
}

body {
  margin: 0;
  font-family: $fontStack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-background);
  color: var(--color-ink);
  @include type-body;
}

.material-icons {
  @include material-icons;
}
