@import '../../shared';

.pack {
  display: flex;
  gap: 8px;
  margin-left: 16px;
  margin-right: 16px;
}

.action {
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  appearance: none;
  border: 0;
  font-family: $fontStack;
  @include type-body($weight: bold);
  padding: 8px 16px;
  border-radius: 9999px;

  &[disabled] {
    opacity: .5;
    cursor: not-allowed;
  }

  &:not(.isPrimary) {
    box-shadow: 0 0 0 1px var(--color-border) inset;
    color: var(--color-accent);

    &[disabled] {
      color: var(--color-ink-disabled);
    }

    &:not([disabled]):hover {
      background-color: var(--color-background-hover);
    }

    &:not([disabled]):active {
      background-color: var(--color-background-active);
    }
  }

  &.isPrimary {
    color: var(--color-background);
    background-color: var(--color-accent);

    &[disabled] {
      background-color: transparent;
      box-shadow: 0 0 0 1px var(--color-border) inset;
      color: var(--color-ink-disabled);  
    }

    &:not([disabled]):hover {
      opacity: .9;
    }

    &:not([disabled]):active {
      opacity: .8;
    }
  }

  &.iconOnly {
    padding: 8px;
  }

  &:not(.iconOnly).hasIcon {
    padding-left: 12px;

    span {
      margin-left: 4px;
    }
  }

  @include narrow {
    &:not(.iconOnly).hasIcon {
      padding: 8px;

      span {
        display: none;
      }
    }
  }
}