@import '../shared';

.field {
  position: relative;
  display: inline-flex;
  width: 36px;
  height: 18px;
  margin: 3px 0;
  background-color: var(--color-background-2);
  box-shadow: 0 0 0 1px var(--color-border) inset;
  border-radius: 9999px;
  cursor: pointer;

  &:focus-within {
    @include focus-outline;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 2px;
    top: 2px;
    width: 14px;
    height: 14px;
    background-color: var(--color-ink-placeholder);
    transform: scale(calc(8 / 14));
    border-radius: 9999px;
    transition: transform .15s ease,
      background-color .15s ease;
  }

  &.isChecked::after {
    transform: translateX(calc(100% + 4px));
    background-color: var(--color-accent);
  }

  &:hover::after {
    opacity: .8;
  }
  &:active::after {
    opacity: .6;
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    appearance: none;
    padding: 0;
    margin: 0;
  }
}
