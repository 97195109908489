@import '../shared';

// property editor

.propertyEditor {
  box-sizing: border-box;
  position: relative;
  min-height: 0;

  --property-spacing: 8px;
  --label-width: 60px;

  .topScrollEdge {
    overflow: hidden;
    height: 24px;

    &::before {
      content: '';
      position: absolute;
      left: 12px;
      top: -10px;
      right: 12px;
      height: 10px;
      box-shadow:
          0 1px 0 var(--color-border),
          0 0 8px rgba(#000, .2);
    }
  }
}

.propertyEditorContent {
  padding: 24px 24px 32px 24px;
  overflow-y: scroll;
}

// groups + properties

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 
    calc(12px + var(--property-spacing))
    0 
    calc(4px + var(--property-spacing)) 
    0;

  h2 {
    @include type-overline;
    color: var(--color-ink-2);
    margin: 0 8px 0 0;
  }

  &::after {
    content: '';
    display: block;
    height: 1px;
    background-color: var(--color-border);
    flex: 1;
  }
}

.properties {
  display: flex;
  flex-direction: column;
  gap: var(--property-spacing);
}

.property {
  display: flex;
  position: relative;
  flex: 0 0 auto;

  &:not(.labelHidden) {
    padding-left: var(--label-width);
  }
}

.propertyLabel {
  position: absolute;
  left: 0;
  top: 0;
  width: var(--label-width);
  height: 24px;
  display: flex;
  align-items: center;
  @include type-body-small($lineHeight: 16px);
  color: var(--color-ink-2);
  cursor: pointer;
}

// common/atomic widgets

.property .button {
  background-color: transparent;
  box-shadow: 0 0 0 1px var(--color-border) inset;
  border-radius: 4px;
  padding: 0;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-ink-2);

  &.iconButton {
    :global(.material-icons) {
      font-size: 16px;
      padding: 4px;
    }
  }

  &:hover {
    background-color: var(--color-background-hover);
    color: var(--color-ink);
  }

  &:active {
    background-color: var(--color-background-active);
    color: var(--color-ink);
  }

  &.isChecked {
    background-color: var(--color-accent-tonal);
    box-shadow: 0 0 0 1px var(--color-accent) inset;  
    color: var(--color-accent);
  }
}
