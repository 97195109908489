.icon {
  position: relative;
  display: flex;
  font-size: 24px;
  line-height: 1;
  width: 1em;
  height: 1em;
  overflow: hidden;
  fill: currentColor;
}

.sprite {
  position: absolute;
  left: 0;
  top: 0;
}