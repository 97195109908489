.widget {
  border-radius: 4px;
  border: 1px solid var(--color-border);
  cursor: pointer;
  height: 24px;
  width: 40px;
  box-sizing: border-box;
  flex: 0 0 auto;
  position: relative;
  overflow: hidden;

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    opacity: 0;
    appearance: none;
    cursor: pointer;
    border: 0;
  }
}

.checkers {
  position: absolute;
  left: -1px;
  top: -1px;
  right: 0;
  bottom: 0;
}

.color {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.popover {
  position: fixed;
  z-index: 2;
}

.scrim {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: default;
  z-index: 1;
}

.picker {
  // sketch picker is always light :-(
  input {
    color-scheme: light;
    --color-focus-outline: #6a3de8;
  }
}