.container {
  position: relative;
}

.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.top,
.left,
.right,
.bottom {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.top {
  bottom: auto;
}

.left {
  right: auto;
}

.right {
  left: auto;
}

.bottom {
  top: auto;
}