@import '../../shared';

.menu {
  position: fixed;
  background-color: var(--color-background-popup);
  padding: 8px 0;
  margin: 0;
  border-radius: 8px;
  box-shadow:
      0 0 0 1px var(--color-border),
      0 4px 12px rgba(#000, .12);
  color: var(--color-ink);
  z-index: 2;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  animation: popup-menu-enter .15s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: 50% 0%;

  @keyframes popup-menu-enter {
    from { transform: scale(0.9); opacity: 0; }
    to { transform: scale(1); }
  }
}

.scrim {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: default;
  z-index: 1;
}

.item {
  width: 100%;
  text-align: left;
  background-color: transparent;
  appearance: none;
  border: 0;
  font-family: $fontStack;
  @include type-body;
  cursor: pointer;
  color: var(--color-ink);
  padding: 8px 20px;
  display: flex;
  align-items: center;

  :global(.material-icons) {
    margin-right: 12px;
    color: var(--color-ink-2);
  }

  &[disabled] {
    cursor: not-allowed;
    color: var(--color-ink-disabled);
  }

  &:not([disabled]):hover {
    background-color: var(--color-background-hover);
  }
  &:not([disabled]):active {
    background-color: var(--color-background-active);
  }
}
